import React, { lazy } from "react";
import CommonProvider from "./context/common";
import AuthProvider from "./context/auth";
import Login from "./pages/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
// defaultTheme
import themes from "./themes";
import "./themes/styles/style.css";

const CommonLayout = lazy(() => import("./layouts/CommonLayout"));

const Dashboard = lazy(() => import("./pages/dashboard/Default"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

// -- Admin Setting Modules : for future enhancement
// import Menu from "./pages/adminsetting/Menu";
// import MenuInfo from "./pages/adminsetting/MenuInfo";

// import RoleAuth from "./pages/adminsetting/RoleAuth";
// import RoleAuthDetail from "./pages/adminsetting/RoleAuthDetail";

// import User from "./pages/adminsetting/User";
// import UserInfo from "./pages/adminsetting/UserInfo";
// import UserInfoCountry from "./pages/adminsetting/UserInfoCountry";

// import UserAuth from "./pages/adminsetting/UserAuth";
// import UserAuthDetail from "./pages/adminsetting/UserAuthDetail";

// -- Customer Modules
const Topup = lazy(() => import("./pages/customer/Topup"));
const TopupInfo = lazy(() => import("./pages/customer/TopupInfo"));
const TopupOnlinePymt = lazy(() => import("./pages/customer/TopupOnlinePymt"));

const ConsignNoteOption = lazy(() =>
  import("./pages/customer/ConsignNoteOption")
);
const ConsignNoteInfo = lazy(() => import("./pages/customer/ConsignNoteInfo"));
const ConsignNoteImport = lazy(() =>
  import("./pages/customer/ConsignNoteImport")
);
const ConsignNoteRedirect = lazy(() =>
  import("./pages/customer/ConsignNoteRedirect")
);

const NotifyCourier = lazy(() => import("./pages/customer/NotifyCourier"));
const NotifyCourierInfo = lazy(() =>
  import("./pages/customer/NotifyCourierInfo")
);

const DeliveryFee = lazy(() => import("./pages/master/DeliveryFee"));

const AddressBook = lazy(() => import("./pages/customer/AddressBook"));
const AddressBookInfo = lazy(() => import("./pages/customer/AddressBookInfo"));
const AddressBookPickInfo = lazy(() =>
  import("./pages/customer/AddressBookPickInfo")
);

const RegistrationInfo = lazy(() =>
  import("./pages/customer/RegistrationInfo")
);
const ProfileInfo = lazy(() => import("./pages/customer/ProfileInfo"));

const Announcement = lazy(() => import("./pages/customer/Announcement"));

const Invoice = lazy(() => import("./pages/customer/Invoice"));

// reports / statement
const TopupStmt = lazy(() => import("./pages/reports/TopupStmt"));
const CODStmt = lazy(() => import("./pages/reports/CODStmt"));

// Ecommerce Platform QR Code scanner
const QRScannerInfo = lazy(() => import("./pages/qrscanner/QRScannerInfo"));
const QRScanner = lazy(() => import("./pages/qrscanner/QRScanner"));
const ScanPayout = lazy(() => import("./pages/qrscanner/ScanPayout"));

const NavigationScroll = lazy(() => import("./layouts/NavigationScroll"));

const MyDocument = lazy(() => import("./pages/MyDocument"));
const RequireAuth = lazy(() => import("./Component/RequireAuth"));
const NotFound = lazy(() => import("./pages/Notfound"));

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes()}>
          <CssBaseline />
          <NavigationScroll>
            <AuthProvider>
              <CommonProvider>
                <Routes>
                  <Route element={<CommonLayout />}>
                    <Route element={<RequireAuth />}>
                      <Route
                        index
                        path="/"
                        exact
                        element={<Dashboard />}
                      ></Route>
                      <Route path="/dashboard" element={<Dashboard />}></Route>
                      <Route
                        path="/reset-password"
                        element={<ResetPassword />}
                      ></Route>
                      <Route
                        path="/printpdf/:refno"
                        element={<MyDocument />}
                      ></Route>

                      {/* Customer Modules */}
                      <Route path="/topup" element={<Topup />}></Route>
                      <Route path="/topup_add" element={<TopupInfo />}></Route>
                      <Route
                        path="/topup_add/:pid"
                        element={<TopupInfo />}
                      ></Route>
                      <Route
                        path="/topup_onlinpay"
                        element={<TopupOnlinePymt />}
                      ></Route>

                      <Route
                        path="/deliver_fee"
                        element={<DeliveryFee />}
                      ></Route>

                      <Route
                        path="/notify_courier"
                        element={<NotifyCourier />}
                      ></Route>
                      <Route
                        path="/issue_notify"
                        element={<NotifyCourierInfo />}
                      ></Route>
                      <Route
                        path="/issue_notify/:id"
                        element={<NotifyCourierInfo />}
                      ></Route>

                      <Route
                        path="/issue_cn_add"
                        element={<ConsignNoteInfo />}
                      ></Route>
                      <Route
                        path="/issue_cn_add/:pid"
                        element={<ConsignNoteInfo />}
                      ></Route>
                      <Route
                        path="/issue_cn_import"
                        element={<ConsignNoteImport />}
                      ></Route>

                      <Route
                        path="/issue_cn"
                        element={<ConsignNoteOption />}
                      ></Route>
                      <Route
                        path="/cn_redirect"
                        element={<ConsignNoteRedirect />}
                      ></Route>

                      <Route
                        path="/address_book"
                        element={<AddressBook />}
                      ></Route>
                      <Route
                        path="/address_add/:typ"
                        element={<AddressBookInfo />}
                      ></Route>
                      <Route
                        path="/address_add/:typ/:id"
                        element={<AddressBookInfo />}
                      ></Route>
                      <Route
                        path="/addresspick_add"
                        element={<AddressBookPickInfo />}
                      ></Route>
                      <Route
                        path="/addresspick_add/:id"
                        element={<AddressBookPickInfo />}
                      ></Route>

                      <Route path="/profile" element={<ProfileInfo />}></Route>
                      <Route
                        path="/announcement"
                        element={<Announcement />}
                      ></Route>

                      <Route path="/invoice" element={<Invoice />}></Route>

                      {/* Reports Modules */}
                      <Route path="/rpt-topup" element={<TopupStmt />}></Route>
                      <Route path="/rpt-cod" element={<CODStmt />}></Route>

                      {/* Ecommerce Platform QR Code scanner */}
                      <Route
                        path="/ecommqrcode"
                        element={<QRScanner />}
                      ></Route>
                      <Route
                        path="/ecommqrcode_add"
                        element={<QRScannerInfo />}
                      ></Route>
                      <Route
                        path="/scanpayout"
                        element={<ScanPayout />}
                      ></Route>

                      {/* Admin Modules - for future enhancement */}
                      {/* <Route path="/menu" element={<Menu />}></Route>
                      <Route path="/menuadd" element={<MenuInfo />}></Route>
                      <Route path="/menuadd/:id" element={<MenuInfo />}></Route>

                      <Route path="/user-auth" element={<UserAuth />}></Route>
                      <Route path="/user-auth/:id" element={<UserAuthDetail />}></Route>

                      <Route path="/user" element={<User />}></Route>
                      <Route path="/useradd" element={<UserInfo />}></Route>
                      <Route path="/useradd/:id" element={<UserInfo />}></Route>
                      <Route path="/usercountry/:ctid/:ctlink" element={<UserInfoCountry />}></Route>

                      <Route path="/role_authorization" element={<RoleAuth />}></Route>
                      <Route path="/role_authorization/:id" element={<RoleAuthDetail />}></Route> */}
                    </Route>
                  </Route>
                  <Route path="/auth" element={<Login />}></Route>
                  <Route
                    path="/register"
                    element={<RegistrationInfo />}
                  ></Route>
                  <Route path="/*" element={<NotFound />}></Route>
                </Routes>
              </CommonProvider>
            </AuthProvider>
          </NavigationScroll>
          <div id="overlay" style={{ display: "none" }}>
            <div className="spinner"></div>
            <br />
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
}

export default App;
