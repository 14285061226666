import http from "../http-common";

const login = (username, password, ityp) => {
  return http.post("/auth/login", JSON.stringify({ username, password, ityp }));
};

const getCurrentUser = (jwt) => {
  return http
    .post("/setting/valitade_token.php", {
      jwt,
    })
    .then((response) => {
      return response.data;
    });
};

const resetPassword = (data) => {
  const newdata = {
    old_password: data.cpass,
    new_password: data.npass,
  };

  return http.post(`/master/user/resetpa`, newdata);
};

const exportedObject = {
  login,
  getCurrentUser,
  resetPassword,
};

export default exportedObject;
